import { Auth, API } from 'aws-amplify';

// TODO: remove Auth header get token from each individual function

export default {

    /**
     * @param {apiName: string, path: string, init: { queryStringParameters: { limit: int, token:}}}
     * @return JSON Promise
     */

    getList: async (params) => {
        params.init.headers = {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
        const { NextToken, ...data } =  await API.get(params.apiName, params.path, params.init);
        return {NextToken, data}

    },

    /**
     * @param { apiName: [string], path: [string], init: {body: {post body}}}
     * @return JSON Promise
     */
    
    postChange: async (params) => {
        params.init.headers = {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
        const data =  await API.post(params.apiName, params.path, params.init);
        return data
    }
}
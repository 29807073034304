import React, { useState, useEffect } from 'react'
import dataProvider from '../services/cognitoDataProvider.js'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Services = () => {
    const classes = useStyles()
    const [services, setServices] = useState([])

    useEffect(() => {
        let nextToken
        dataProvider.getList(
            {
                apiName: 'PolicyServer',
                path: '/api/services',
                init:{
                    queryStringParameters: {
                        "limit": null,
                        "token": nextToken
                    }
                }
            }
        ).then( response => {
            setServices(response.data[0].services)
            nextToken = response.NextToken
        })
    }, [])

    return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Service Name</TableCell>
                <TableCell>Description</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

export default Services

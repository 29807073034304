import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import dataProvider from "../services/cognitoDataProvider";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { propStyle } from "aws-amplify-react";
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "100%",
    },
  },
  button: {
    margin: "10px 16px 50px 20px",
  },
  paperContainer: {
    width: "",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "120px",
    maxWidth: "80%",
    display: "flex",
    align: "left",
  },
  groupList: {
    margin: theme.spacing(1),
    minWidth: "120px",
    maxWidth: "80%",
  },
  base: {
    margin: theme.spacing(1)
  },
  title: {
    paddingTop: '20px'
  },
  select: {
    margin: "16px",
  },
  selectLabel: {
    paddingLeft: theme.spacing(2),
  },
}));



const UserGroups = (props) => {

  if (!props.location.username){props.history.push('/users')}

  const [username, setUsername] = useState('')
  const classes = useStyles()
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([])
  const [userGroup, setUserGroup] = useState("");

  const canSubmit = !userGroup

  const handleAddGroup = (event) => {
    dataProvider
      .postChange({
        apiName: "AdminQueries",
        path: "/addUserToGroup",
        init: {
          body: {
            username: event.username,
            groupname: event.userGroup,
          },
        },
      })
      .then((response) => {
        // reload user groups or return to users.
        getUserGroups()
      });
  };
  
  const handleRemoveGroup = (username,groupName) => {
    dataProvider
      .postChange({
        apiName: "AdminQueries",
        path: "/removeUserFromGroup",
        init: {
          body: {
            username: username,
            groupname: groupName,
          },
        },
      })
      .then((response) => {
        // reload user groups or return to users.
        getUserGroups()
        
      });
  };

  const getUserGroups = () => {
    let nextToken;
    dataProvider
      .getList({
        apiName: 'AdminQueries',
        path: "/listGroupsForUser",
        init: {
          queryStringParameters: {
            limit: null,
            token: nextToken,
            username: props.location.username
          },
        }
      })
      .then(response => {
        console.log('groupsforuser:', response.data)
        setUserGroups(response.data.Groups)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (!username) {}

    setUsername(props.location.username)
    
    getUserGroups()
  },[])



  if (!groups.length) {
    let nextToken;
    dataProvider
      .getList({
        apiName: "PolicyServer",
        path: "/api/groups",
        init: {
          queryStringParameters: {
            limit: null,
            token: nextToken,
          },
        },
      })
      .then((response) => {
        let serviceArray = Object.values(response.data);
        console.log(serviceArray);
        setGroups(serviceArray);
        nextToken = response.NextToken;
      });
  }

  return (
    <Paper className={classes.paperContainer}>

      <div className={classes.groupList}>
        <Typography variant="h5" className={[classes.base, classes.title]}>Current groups for: {username}</Typography>
        <List>
          {userGroups.map((row, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={row.GroupName}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={()=>{handleRemoveGroup(username, row.GroupName)} } >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
      <form className={classes.root}>
        <FormControl required className={classes.formControl}>
          <InputLabel className={classes.selectLabel}>Group</InputLabel>
          <Select
            id="group"
            label="group"
            placeholder={"User Group"}
            className={classes.select}
            onChange={(e) => setUserGroup(e.target.value)}
            value={userGroup}
          >
            {groups.map((g) => (
              <MenuItem key={g.name} value={g.name}>
                {g.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            disabled={canSubmit}
            className={classes.button}
            onClick={() =>
              handleAddGroup({
                username,
                userGroup,
                routeToUser: true,
              })
            }
          >
            Add to Group
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default UserGroups;

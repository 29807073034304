import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import ListIcon from '@material-ui/icons/Layers';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';


import { Link as RouterLink } from 'react-router-dom'

export const mainListItems = (
  <div>
    <ListItem button {...{ component: RouterLink, to: "/"}}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>

    <ListItem button {...{ component: RouterLink, to: "/users" }} >
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Users"/>
    </ListItem>

    <ListItem button {...{ component: RouterLink, to: "/groups" }}>
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary="Groups" />
    </ListItem>

    <ListItem button {...{ component: RouterLink, to: "/services" }}>
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText primary="Services" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>User Actions</ListSubheader>
    <ListItem button {...{ component: RouterLink, to: "/create-user"}}>
      <ListItemIcon>
        <PersonAddIcon />
      </ListItemIcon>
      <ListItemText primary="Add User" />
    </ListItem>
  </div>
);

export const tertiaryListItems = (
    <div>
    <ListSubheader inset>Group Actions</ListSubheader>
    <ListItem button {...{ component: RouterLink, to: "/manage-services"}}>
      <ListItemIcon>
        <LibraryAddCheckIcon />
      </ListItemIcon>
      <ListItemText primary="Manage Services" />
    </ListItem>
  </div>
)

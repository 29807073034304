import React, { useState, useEffect } from 'react'
import dataProvider from '../services/cognitoDataProvider.js'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


const ADMIN_ROLE_NAME = 'Admin'



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ManageServices = () => {
    const classes = useStyles()
        const [groups, setGroups] = useState([])

    const [services, setServices] = useState([])



    if(!services.length>0){
                let nextToken
        dataProvider.getList(
            {
                apiName: 'PolicyServer',
                path: '/api/services',
                init:{
                    queryStringParameters: {
                        "limit": null,
                        "token": nextToken
                    }
                }
            }
        ).then( response => {
            console.log()
            setServices(response.data[0].services)
            nextToken = response.NextToken
        })
    }


    useEffect(() => {
        let nextToken
        dataProvider.getList(
            {
                apiName: 'PolicyServer',
                path: '/api/groups',
                init:{
                    queryStringParameters: {
                        "limit": null,
                        "token": nextToken
                    }
                }
            }
        ).then( response => {
            let serviceArray = Object.values(response.data)
            console.log(serviceArray)
            setGroups(serviceArray)
            nextToken = response.NextToken
        })
    }, [])

    return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Group Name</TableCell>
                <TableCell>Collections:</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {/*hide admin role as we have been granted higher level access*/}
              {groups.filter(g=>g.name!==ADMIN_ROLE_NAME).map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell>
                  <Grid container direction="column" justify="flex-start"
                        alignItems="flex-start">

                {row.services.map((service,index) => {
                        return <Grid key={index}>{service.name}<RemoveService props={{groups, setGroups, service , group: row}}/></Grid>
                    })}</Grid>
                  </TableCell>
                      <TableCell>

                           <Grid container direction="column" justify="flex-start"
                        alignItems="flex-start">
                          {services.filter(x=>row.services.map(x=>x.name).indexOf(x.name)===-1).map((service ,index) => {
                        return  <Grid key={index}>{service.name}<AddService props={{groups, setGroups, service, group: row}}/></Grid>

                          })}</Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}
//NOTE Add and remove service are doing the backend and ui update seperately.
//need a better pattern for this,
const AddService = ({props}) => {
    const {groups, setGroups, service, group} = props
    console.log(service)
    return <AddCircleOutlineIcon onClick={()=>{
        console.log(service, group)
        const targeted  = groups.indexOf(group)
        groups[targeted].services.push(service)
        dataProvider.postChange({
                                       apiName: 'PolicyServer',
                                          path: '/api/addService',
                                          init: {
                                              body: {
                                                  group: group.name,
                                                  policy: service.name
                                              }
                                          }
                                      }).catch(err => console.log(err));
        setGroups([...groups])
    }}/>
}

const  RemoveService = ({props}) => {
    const {groups, setGroups, service, group} = props
    console.log(service)
    return <RemoveCircleIcon onClick={()=>{
        console.log(service, group)
        const targeted  = groups.indexOf(group)
        groups[targeted].services = groups[targeted].services.filter(x=>x!==service)
        dataProvider.postChange({
                                           apiName: 'PolicyServer',
                                              path: '/api/removeService',
                                              init: {
                                                  body: {
                                                      group: group.name,
                                                      policy: service.name
                                                  }
                                              }
                                          }).catch(err => console.log(err));
        setGroups([...groups])
    }}/>
}


export default ManageServices

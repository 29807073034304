import React, { useState, useEffect } from "react";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

Amplify.configure(awsconfig);

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {}
}));

const Home = () => {
  const classes = useStyles();
  const [user, setUser] = useState([]);
  const [group, setGroup] = useState([]);

  // Get authenticated user

  function loadUser() {
    return Auth.currentAuthenticatedUser({ bypassCache: true });
  }
  useEffect(() => {
    async function onLoad() {
      try {
        const user = await loadUser();
        setUser(user);
        setGroup(user.signInUserSession.idToken.payload["cognito:groups"]);
      } catch (error) {
        console.log(error);
      }
    }
    onLoad();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={classes.paper}>
          <label>User is: </label>
          <label>{user.username}</label>
          <br></br>
          <label>Group is:</label>
          <label>{group}</label>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Home;

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9f04b114-0a1b-4c31-aff2-168d5d0f26fa",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YT4Hv9PTG",
    "aws_user_pools_web_client_id": "99tnj1lpqrdoch2ole18hn82j",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://pzu377gpz5eepefcuo3hh73fni.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qhaqru7ynngu5ezi4st2prg5i4",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://kiooijzb48.execute-api.us-east-1.amazonaws.com/cops",
            "region": "us-east-1"
        },
        {
            "name": "fileRetrival",
            "endpoint": "https://45rn241y0h.execute-api.us-east-1.amazonaws.com/cops",
            "region": "us-east-1"
        },
        {
            "name": "collectionManager",
            "endpoint": "https://owl15u44qh.execute-api.us-east-1.amazonaws.com/cops",
            "region": "us-east-1"
        },
        {
            "name": "PolicyServer",
            "endpoint": "https://p82x81lxig.execute-api.us-east-1.amazonaws.com/cops",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "copsdata145054-cops",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "c81170a8458d45df83e4e0e0f762b0f1",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;

//@flow

import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import {withStyles, makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import {green} from '@material-ui/core/colors'
import {SignIn} from "aws-amplify-react";
import Auth from "@aws-amplify/auth";
import {ConsoleLogger as Logger} from "@aws-amplify/core/lib-esm/Logger/ConsoleLogger";



const logger = new Logger('SignIn');

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    }
})

class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
        // this.classes =  useStyles()
        this.classes = {}
    }

    async signIn(event) {
        // avoid submitting the form
        if (event) {
            event.preventDefault();
        }

        const username = this.getUsernameFromInput() || '';
        const password = this.inputs.password;

        if (!Auth || typeof Auth.signIn !== 'function') {
            throw new Error(
                'No Auth module found, please ensure @aws-amplify/auth is imported'
            );
        }
        this.setState({loading: true});
        try {
            const user = await Auth.signIn(username, password);
            logger.debug(user);
            const groups = user.signInUserSession.idToken.payload['cognito:groups']
            if (groups.indexOf('Admin') < 0) {
                throw "Only Administrators can use this interface."
            }

            if (
                user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA'
            ) {
                logger.debug('confirm user with ' + user.challengeName);
                this.changeState('confirmSignIn', user);
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                logger.debug('require new password', user.challengeParam);
                this.changeState('requireNewPassword', user);
            } else if (user.challengeName === 'MFA_SETUP') {
                logger.debug('TOTP setup', user.challengeParam);
                this.changeState('TOTPSetup', user);
            } else if (
                user.challengeName === 'CUSTOM_CHALLENGE' &&
                user.challengeParam &&
                user.challengeParam.trigger === 'true'
            ) {
                logger.debug('custom challenge', user.challengeParam);
                this.changeState('customConfirmSignIn', user);
            } else {
                this.checkContact(user);
            }
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                logger.debug('the user is not confirmed');
                this.changeState('confirmSignUp', {username});
            } else if (err.code === 'PasswordResetRequiredException') {
                logger.debug('the user requires a new password');
                this.changeState('forgotPassword', {username});
            } else {
                this.error(err);
            }
        } finally {
            this.setState({loading: false});
        }
    }

    //NOTE it will not display the component extended default unless i implement a gui.
    // this pulls the log in from story core and adapts it back to a react class format.
    showComponent(theme) {
        let error, fetching
        let {classes} = this.props
        return (
            <Container component='main' maxWidth='xs'>
                <div className={classes.paper}>
                    <div className={classes.wrapper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        {fetching && (
                            <CircularProgress size={55}
                                              className={classes.fabProgress}/>
                        )}
                    </div>
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    {error && (
                        <Typography component='h1' variant='subtitle1'>
                            {error}
                        </Typography>
                    )}
                    <form className={classes.form} noValidate>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='username'
                            label='Email Address'
                            name='username'
                            key='username'
                            onChange={this.handleInputChange}
                            // autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            key='password'
                            onChange={this.handleInputChange}
                            // autoComplpete="current-password"
                            value={undefined}
                        />


                        <FormControlLabel
                            control={<Checkbox value='remember' color='primary'/>}
                            label='Remember me'
                        />
                        <Button
                            fullWidth
                            id='sign-in-button'
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            onClick={this.signIn}
                        >
                            Sign In
                        </Button>
                    </form>
                     <Grid item xs>
                <Link
                  variant='body2'
                  onClick={() => super.changeState("forgotPassword")}
                >
                  Forgot password?
                </Link>
              </Grid>
                </div>

            </Container>
        )
    }


}

CustomSignIn.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CustomSignIn)

import React, { Fragment, useState } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import dataProvider from "../services/cognitoDataProvider";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "100%",
    },
  },
  button: {
    margin: "10px 16px 50px 20px",
  },
  paperContainer: {
    width: "300px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "120px",
    maxWidth: "80%",
    display: "flex",
    align: "left",
  },
  select: {
    margin: "16px",
  },
  selectLabel: {
    paddingLeft: theme.spacing(2),
  },
}));

const handleSubmit = (event) => {
  Auth.signUp({
    username: event.userName,
    password: event.userPassword,
    attributes: {
      email: event.userEmail, // optional
      phone_number: event.userPhone, // optional - E.164 number convention
      // other custom attributes
    },
    validationData: [], //optional
  })
    .then((data) => {
      dataProvider.postChange({
        apiName: "AdminQueries",
        path: "/addUserToGroup",
        init: {
          body: {
            username: event.userName,
            groupname: event.userGroup,
          },
        },
      });
    })
    .catch((err) => console.log(err));
};

const handleChange = (e, func) => {
  func(e.target.value);
};

const CreateUser = () => {
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [groups, setGroups] = useState([]);
  const classes = useStyles();
  const allowSubmit = !(
    userName &&
    userEmail &&
    userPhone &&
    userPassword &&
    userGroup
  );

  if (!groups.length) {
    let nextToken;
    dataProvider
      .getList({
        apiName: "PolicyServer",
        path: "/api/groups",
        init: {
          queryStringParameters: {
            limit: null,
            token: nextToken,
          },
        },
      })
      .then((response) => {
        let serviceArray = Object.values(response.data);
        console.log(serviceArray);
        setGroups(serviceArray);
        nextToken = response.NextToken;
      });
  }
  return (
    <Paper className={classes.paperContainer}>
      <form className={classes.root} onSubmit={handleSubmit}>
        <FormControl className={classes.formControl}>
          <TextField
            id="username"
            label="Username"
            onChange={(e) => handleChange(e, setUserName)}
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="email"
            label="email"
            onChange={(e) => handleChange(e, setUserEmail)}
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="phone"
            label="phone"
            required
            onChange={(e) => handleChange(e, setUserPhone)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="password"
            label="password"
            type="password"
            onChange={(e) => handleChange(e, setUserPassword)}
          />
        </FormControl>
        <FormControl required className={classes.formControl}>
          <InputLabel className={classes.selectLabel}>Group</InputLabel>
          <Select
            id="group"
            label="group"
            placeholder={"User Group"}
            className={classes.select}
            onChange={(e) => setUserGroup(e.target.value)}
            value={userGroup}
          >
            {groups.map((g) => (
              <MenuItem key={g.name} value={g.name}>
                {g.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            disabled={allowSubmit}
            className={classes.button}
            onClick={() =>
              handleSubmit({
                userName,
                userEmail,
                userPhone,
                userPassword,
                userGroup,
              })
            }
          >
            Create User
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default CreateUser;

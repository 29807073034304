import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dataProvider from '../services/cognitoDataProvider.js'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Users = () => {
    const classes = useStyles()
    const [users, setUser] = useState([])

    const postUserChange = (username, isActive) => {

      let pathType = isActive ? '/disableUser' : '/enableUser' 

      dataProvider.postChange(
        {                
          apiName: 'AdminQueries',
          path: pathType, 
          init:{ 
            body: {
              username: username
            }
          }
        }
      ).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.log(error.message)
        // TODO: Revert state on error.
        // could take old take object as function param and use setUsers([...oldState])

      })
    }

    const onUserActivationChange = (idx, oldActiveState) => {

      // Optimistically update our state:

      let newActiveState = !oldActiveState
      let newUsers = users
      newUsers[idx].Enabled = newActiveState
      setUser([...newUsers])

      // Post changes to API:
      postUserChange(users[idx].Username, oldActiveState)
    }

    useEffect(() => {

        let nextToken
        dataProvider.getList(
            {
                apiName: 'AdminQueries',
                path: '/listUsers',
                init:{
                    queryStringParameters: {
                        "limit": null,
                        "token": nextToken
                    }
                }
            }
        ).then( response => {
            setUser(response.data.Users)
            nextToken = response.NextToken
        })
    }, [])

    const findAttribute = (arr, attr) => {
        let obj = arr.find(obj => obj.Name === attr)
        if (obj) {return obj.Value}
    }

    return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Active</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.Username}
                  </TableCell>
                  <TableCell>{findAttribute(row.Attributes, "email")}</TableCell>
                  <TableCell>{findAttribute(row.Attributes, "phone_number")}</TableCell>
                  <TableCell>{row.UserStatus}</TableCell>
                  <TableCell><Switch checked={row.Enabled} onChange={() => onUserActivationChange(index, row.Enabled)}></Switch></TableCell>
                  <TableCell><Link to={{pathname: '/user-groups', username: row.Username}}>Groups</Link></TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

export default Users
